import React, { useState, useEffect } from 'react';
import bridge from '@vkontakte/vk-bridge';
import PropTypes from 'prop-types';
import axios from 'axios'; 
import Countdown from 'react-countdown-now';
import { Panel, PanelHeader, Button, Placeholder } from '@vkontakte/vkui';
import { Icon28CheckbackPrizeOutline } from '@vkontakte/icons'; 

import MagicBall from './../components/MagicBall';

 
const Start = ({ id, go, startUrls }) => { 
 

	 
	return ( 
	<Panel id={id}> 
		 
		<PanelHeader>Предсказание</PanelHeader>											  
  
		<Placeholder 
            header={<>Не упустите свой шанс</>}
        > 
        <MagicBall/>

		узнать свое предсказание на сегодня
        <br/>
        <Button onClick={go} data-to={startUrls} style={{marginTop: 20}} className='predicted-btn-rose' size="l">Далее</Button>

        </Placeholder>

	</Panel>
)};

 
export default Start;
