import React, { useState, useEffect } from 'react';
import bridge from '@vkontakte/vk-bridge';
import PropTypes from 'prop-types';
import axios from 'axios'; 
import Countdown from 'react-countdown-now';
import { Panel, PanelHeader, PanelHeaderButton, Spinner, ButtonGroup, Button, Group, Cell, Div, Avatar, Placeholder } from '@vkontakte/vkui';
import { Icon28CheckbackPrizeOutline } from '@vkontakte/icons'; 

import MagicBall from './../components/MagicBall';

import mag from "./../img/mag.png";
import star from "./../img/star.png";
import money from "./../img/mag-money.png";
import money30 from "./../img/mag30x30.png";

 
const Home = ({ id, go, fetchedUser, userDb, setUserDb, timer, setActiveModal, setSnackbar, setSnackbarText }) => { 
 
	const [spinner, setSpinner] = useState(true);
 
	// @ useEffect
	useEffect(() => { 
		setSpinner(false);
	}, []);
	 
	// Клик на Посмотреть
	const clickView = e => {
		setSpinner(true);
		// @ Отправка axios 
		axios.get('https://predicted.botzik.ru/api/predicted/view?'+window.location.search.slice(1))
		.then(response => {		 
			// Проверка прошла 
			if(response.data.status == 'success'){	
				// Вывод 
				setUserDb(response.data.user); 
				setSpinner(false);				   
			}else{     
				// Snackbar error			 
				setSnackbar('error');  
				// Snackbar Text 				 	
				setSnackbarText(response.data.text);  
				setSpinner(false);
			} 
		}).catch(error => {	
			// Snackbar error		  
			setSnackbar('error'); 
			// Snackbar Text			 
			setSnackbarText(error.message);  
		});	 

	}


	// Клик на кнопку поделиться
	const clickShare = e => {
		bridge.send('VKWebAppShowWallPostBox', {
			message: 'Предсказание на день! \n' + userDb.text,
			attachment: 'https://vk.com/app51674843#ref' + userDb.user_id,
			owner_id: fetchedUser.id
		  	}).then( (data) => {		 
				// Snackbar error		  
				setSnackbar('success'); 
				// Snackbar Text			 
				setSnackbarText('Ссылка успешно отправлена'); 			 
			}).catch( (e) => {
				// Snackbar error		  
				setSnackbar('error'); 
				// Snackbar Text			 
				setSnackbarText('Запись не была добавлена'); 	
		  })
	}
 
	 
	return ( 
	<Panel id={id}>
		 
		<PanelHeader before={<PanelHeaderButton><Icon28CheckbackPrizeOutline onClick={() => setActiveModal('gift')} className='count-anim'/></PanelHeaderButton>}>Предсказание</PanelHeader>											  
 
		<div className='menu-left'>
			<img onClick={go} data-to="myPredictions" src={mag} alt="Предсказание"/>
			<div>Создать</div>
		</div>
		<div className='menu-right'>
			<img onClick={go} data-to="rating" src={star} alt="Рейтинг"/>
			<div>Рейтинг</div>
		</div>
 
		<Placeholder 
		header={<>

				<div style={{fontSize: 32, marginTop: 55}}>
					Осталось: <img src={money30} className='money-img' alt="Баланс"/> {userDb && (userDb.money)}
				</div> 

				<br/>
				{userDb.text ? (  
					<>				
					Получите бесплатный<br/> 
					магический шар через <br/> 
					<div className='predicted-time'> 
						<Countdown date={Date.now() + timer * 1000} renderer={({ hours, minutes, seconds }) => ( <span>{hours}:{minutes}:{seconds}</span> )} /> 
					</div>
					</>				 
				):(
					<> 
					{fetchedUser && (<>{fetchedUser.first_name},</>)}  {' '}
					для Вас есть предсказание.<br/>  Хотите заглянуть в будущее? 
					</>	
				)}
		</> 
		}> 
		<>  

		 
			{userDb.text ? (
				<>

				<div className="box box1">
					<div className="oddboxinner"> 
					{spinner ? (
						 <Spinner size="large" style={{ margin: '20px 0' }} />
					):(
						 
						<>{userDb.text}</>
					)}
					</div>
				</div> 

				<Button onClick={clickShare} className='predicted-btn-violet' size="l">Поделиться </Button>
				<Button onClick={clickView} className='predicted-btn-rose' size="l">Еще за 1 <img src={money} className='money-img' alt="Баланс"/></Button>
				
				<div style={{marginTop: 30}}>									    
					Порадуйте ваших друзей <br/>своим личным предсказанием
				</div>	

				<br/>

				<Button onClick={go} data-to="myPredictions" className='predicted-btn-red' size="l">Подробней</Button>
				</>  
				):(
				<>
				<MagicBall/>
				<Button onClick={clickView} className='predicted-btn-rose' size="l">Узнать за 1 <img src={money} className='money-img' alt="Баланс"/></Button>
				</>
			)}		

			  
			 



		</>	

        </Placeholder>



		{/*}
		<Group>
		{fetchedUser &&
		<Group header={<Header mode="secondary">User Data Fetched with VK Bridge</Header>}>
			<Cell
				before={fetchedUser.photo_200 ? <Avatar src={fetchedUser.photo_200}/> : null}
				subtitle={fetchedUser.city && fetchedUser.city.title ? fetchedUser.city.title : ''}
			>
				{`${fetchedUser.first_name} ${fetchedUser.last_name}`}
			</Cell>
		</Group>}

		<Group header={<Header mode="secondary">Navigation Example</Header>}>
			<Div>
				<Button stretched size="l" mode="secondary" onClick={go} data-to="persik">
					Show me the Persik, please
				</Button>
			</Div>
		</Group>
		</Group>
		{*/}

	</Panel>
)};

Home.propTypes = {
	id: PropTypes.string.isRequired,
	go: PropTypes.func.isRequired,
	fetchedUser: PropTypes.shape({
		photo_200: PropTypes.string,
		first_name: PropTypes.string,
		last_name: PropTypes.string,
		city: PropTypes.shape({
			title: PropTypes.string,
		}),
	}), 
};
 
export default Home;
