import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { ScreenSpinner, Panel, Avatar, PanelHeader, Button, SimpleCell, IconButton, FormItem, SegmentedControl, PanelHeaderBack, Group } from '@vkontakte/vkui';
import { Icon28Users, Icon28EditOutline } from '@vkontakte/icons'; 
 
import ratingImg from "./../img/rating.png";
 
const Rating = ({ id, go, setUserDb, setPopout, setSnackbar, setSnackbarText }) => { 

const [rating, setRating] = useState([]); 
const [ratingOne, setRatingOne] = useState([]); 
const [ratingMonth, setRatingMonth] = useState([]); 

const [selectedValue, setSelectedValue] = useState('one');
function onSegmentedControlChange(newValue) {
    setSelectedValue(newValue)
}

// @ useEffect
useEffect(() => {
 
	// @ Отправка axios 
	axios.post('https://predicted.botzik.ru/api/rating?'+window.location.search.slice(1))
	.then(response => {		 
		// Проверка прошла 
		if(response.data.status == 'success'){	
            console.log(response.data.rating_one.data);
			// Вывод  
            setUserDb(response.data.user);
            setRating(response.data.rating_view.data);
            setRatingOne(response.data.rating_one.data);
            setRatingMonth(response.data.rating_one.data);
 
			// Выключить Spinner 
			setPopout(null); 	
			   
		}else{   
			// Snackbar error			 
			setSnackbar('error');  
			// Snackbar Text 				 	
			setSnackbarText(response.data.text);  
			// Выключить Spinner 
		setPopout(null);
		} 
	}).catch(error => {	
		// Snackbar error		  
		setSnackbar('error'); 
		// Snackbar Text			 
		setSnackbarText(error.message);  
		// Выключить Spinner 
		setPopout(null);
	});

}, []);
 
return ( 
	<Panel id={id}>
		<PanelHeader
			before={<PanelHeaderBack onClick={go} data-to="home"/>}
		>
			Рейтинг
		</PanelHeader>
 
		<Group className='Bg'>

         
            <FormItem>
              <SegmentedControl
                size="m"
                name="type"
                value={selectedValue}
                options={[
                  {
                    label: 'Сегодня',
                    value: 'one',
                  },
                  {
                    label: 'За месяц',
                    value: 'month',
                  },
                  {
                    label: 'Все время',
                    value: 'all',
                  },
                ]}
                onChange={onSegmentedControlChange}
              />
            </FormItem>


            {selectedValue == 'one' && (    
			    ratingOne.length > 0 && (
                <>  
                {ratingOne.map((itemHome, index) => (
                    <div key={index} className="boxBlock">
                        <SimpleCell
                            className='boxOddboxinner'
                            disabled
                            before={<Avatar size={48} src={itemHome.photo} fallbackIcon={<Icon28Users />} />}     
                            indicator={itemHome.view_one}                  
                            after={<img src={ratingImg} className='anim' alt="Предсказание"/> }  
                            subtitle={<>Сегодня {itemHome.view_one} предсказаний</>}
                        >
                            {itemHome.name} {itemHome.last_name}  
                        </SimpleCell>
                    </div> 	
                ) 
                )}
                </>
			    )	
            )} 

            {selectedValue == 'month' && (    
			    ratingMonth.length > 0 && (
                <>  
                {ratingMonth.map((itemHome, index) => (
                    <div key={index} className="boxBlock">
                        <SimpleCell
                            className='boxOddboxinner'
                            disabled
                            before={<Avatar size={48} src={itemHome.photo} fallbackIcon={<Icon28Users />} />}     
                            indicator={itemHome.view_month}                  
                            after={<img src={ratingImg} className='anim' alt="Предсказание"/> }  
                            subtitle={<>{itemHome.view_month} предсказаний</>}
                        >
                            {itemHome.name} {itemHome.last_name}  
                        </SimpleCell>
                    </div> 	
                ) 
                )}
                </>
			    )	
            )} 

            {selectedValue == 'all' && (    
			    rating.length > 0 && (
                <>  
                {rating.map((itemHome, index) => (
                    <div key={index} className="boxBlock">
                        <SimpleCell
                            className='boxOddboxinner'
                            disabled
                            before={<Avatar size={48} src={itemHome.photo} fallbackIcon={<Icon28Users />} />}     
                            indicator={itemHome.view}                  
                            after={<img src={ratingImg} className='anim' alt="Предсказание"/> }  
                            subtitle={<>{itemHome.view} предсказаний</>}
                        >
                            {itemHome.name} {itemHome.last_name}  
                        </SimpleCell>
                    </div> 	
                ) 
                )}
                </>
			    )	
            )} 

		</Group>
	</Panel>
)};

Rating.propTypes = {
	id: PropTypes.string.isRequired,
	go: PropTypes.func.isRequired,
};

export default Rating;
