import React, { useState, useEffect } from 'react';
import bridge from '@vkontakte/vk-bridge';
import axios from 'axios';
import { ModalRoot, ModalCard, View, ScreenSpinner, FormItem, IconButton, SimpleCell, Avatar, Textarea, ButtonGroup, Button, AdaptivityProvider, AppRoot, ConfigProvider, SplitLayout, SplitCol, Snackbar } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';
import { Icon28CheckCircleOn, Icon28Cancel, Icon56FavoriteOutline, Icon28CancelCircleFillRed, Icon56Stars3Outline, Icon56NotificationOutline } from '@vkontakte/icons'; 

import Start from './panels/Start';
import Home from './panels/Home';
import MyPredictions from './panels/MyPredictions';
import Friends  from './panels/Friends';
import Rating  from './panels/Rating';


import ratingImg from "./img/rating.png";
import mag from "./img/mag.png";
 

const App = () => {
	const [activePanel, setActivePanel] = useState('start'); 
	const [startUrls, setStartUrls] = useState('home');
	 
	const [fetchedUser, setUser] = useState(null);
	const [popout, setPopout] = useState(<ScreenSpinner size='large' />);
	const [snackbar, setSnackbar] = useState(null);  
	const [snackbarText, setSnackbarText] = useState('Ошибка');
	const snackbarBlock =  snackbar &&  <Snackbar duration="5000"  onClose={() => setSnackbar(null)} layout='horizontal' before={snackbar == 'success' ?  <Icon28CheckCircleOn />  : <Icon28CancelCircleFillRed /> }> {snackbarText}</Snackbar>;
	const hashUrl = window.location.hash;  
	const [hash, setHash] = useState(null);
	const [timer, setTimer] = useState(null);
	const [activeModal, setActiveModal] = useState(null); 
	const [predictedAll, setPredictedAll] = useState([]);
	const [userDb, setUserDb] = useState([]);
	const [idPredicted, setIdPredicted] = useState(null);
	const [urlShare, setUrlShare] = useState(null);

	const [step, setStep] = useState('1');
	 
	 
	const [friends, setFriends] = useState([]); 
	const [predictedText, setPredictedText] = useState('');

	const handleTextareaChange = (event) => {
		setPredictedText(event.target.value);
	}; 
	  
	// @ useEffect
	useEffect(() => { 
 
		// @ Отправка  события "Данные с Вк"   
		bridge.send('VKWebAppGetUserInfo')
		.then((data) => { 
			// Данные с Вк   
			setUser(data);
   

			// @ Отправка axios 
			axios.post('https://predicted.botzik.ru/api/start?'+window.location.search.slice(1), {
				data: data, ref: hashUrl
			}).then(response => {		 
				// Проверка прошла 
				if(response.data.status == 'success'){	
       
					// @ Поулучить Hash ID
					if(hashUrl){		
						setHash(hashUrl.substring(1))	
						setStartUrls('friends');	  			 
					}else{
						setStartUrls('home');
					}

					// Вывод  
					setUserDb(response.data.user); 
					setTimer(response.data.timer);		 
					

					// Третий вход
					// Свободно

					// Второй вход
					if(response.data.user.visit == 2){ 					
						// Окно на отправку уведомлений
						setActiveModal('modalNotifications'); 						
					}	

					// Третий вход
					// Рекомендовать друзьям (авто от вк)


					// Четвертый вход
					if(response.data.user.visit == 4 || response.data.user.visit == 10){
						// Окно с предложением добавить мини-приложение 
						setActiveModal('modalFavorites'); 							
					}

					// Пятый вход
					if(response.data.user.visit == 5){  
						// Подписаться на сообщество
						setActiveModal('modalJoinGroup'); 
					}

					 
					 
					// Выключить Spinner 
						setTimeout(() => { setPopout(<ScreenSpinner state="done" aria-label="Успешно" />);
						setTimeout(() => setPopout(null), 1000);
					}, 2000); 	

				}else{   
					// Snackbar error			 
					setSnackbar('error');  
					// Snackbar Text 				 	
					setSnackbarText(response.data.text);  
					// Выключить Spinner 
					setPopout(null);
				} 
			}).catch(error => {	
				// Snackbar error		  
				setSnackbar('error'); 
				// Snackbar Text			 
				setSnackbarText(error.message);  
				// Выключить Spinner 
				setPopout(null);
			});
			   
		}).catch((error) => {
			// Snackbar error 
			setSnackbar('error');   
			// Snackbar Text			 
			setSnackbarText('Ошибка: VKWebAppGetUserInfo');
			// Выключить Spinner 
			setPopout(null);
		});
 
	}, []);


	const ClickGetFriends = e => {
		bridge.send('VKWebAppGetFriends')
		.then((data) => { 
			if (data) {
				// Данные о пользователях
				setFriends(data.users);
				setPredictedText('');
				setStep('2');
			}
		})
		.catch((error) => {
			// Ошибка
		});
	};  

	const go = e => {
		setActivePanel(e.currentTarget.dataset.to);
	}; 

	// Click скопировать ссылку и отправить
	const ClicCopyUrl = e => {

		// Скопировать ссылку
		bridge.send('VKWebAppCopyText', {
			text: "Посмотри, для тебя есть предсказание vk.com/app51674843#" + e.currentTarget.dataset.to
			}).then((data) => { 
			  if (data.result) {
				// Текст скопирован в буфер обмена
				setSnackbar('success'); // success	
				setSnackbarText('Ссылка скопирована в буфер обмена'); // success Text
			  } else {
				// Ошибка
			}
		}).catch((error) => {
			// Ошибка
			console.log(error);
		}); 

		// Поделиться ссылкой
		bridge.send('VKWebAppShare', {
			link: 'https://vk.com/app51674843#' + e.currentTarget.dataset.to
		}).then((data) => { 
			if (data.result) {
				setSnackbar('success');  // error 				 	
				setSnackbarText('Ссылка успешно отправлена'); // error Text
			}
		}).catch((error) => {
			// Ошибка
			setSnackbar('error');  // error 				 	
			setSnackbarText('Ссылка не отправлена'); // error Text
		});
  
	}; 
	 


	// Click добавить  
	const ClickAdd = e => {
		axios.post('https://predicted.botzik.ru/api/predicted/add?' + window.location.search.slice(1), {
		predictedText: predictedText,
		friends: friends
		}).then(response => {
			// Проверяем статус error или success
			if (typeof response.data.status !== 'undefined') {
				// Проверка не прошла 
				if(response.data.status == 'error'){				   
					// Snackbar Error
					setSnackbar('error');  
					setSnackbarText(response.data.text);
				}
				// Проверка прошла  
				if(response.data.status == 'success'){	
					
					setPredictedAll(response.data.predicted_list);				
					setUrlShare(response.data.url);
					setStep('3');
					// Скопировать ссылку
					bridge.send('VKWebAppCopyText', {
						text: "Посмотри, для тебя есть предсказание vk.com/app51674843#" + response.data.url
						}).then((data) => { 
						  if (data.result) {
							// Текст скопирован в буфер обмена
							setSnackbar('success'); // success	
							setSnackbarText('Ссылка скопирована в буфер обмена'); // success Text
						  } else {
							// Ошибка
						}
					}).catch((error) => {
						// Ошибка
						console.log(error);
					}); 
				}
			}
		}).catch(error => {
			// Snackbar error   
			setSnackbar('error');  	
			if(error.response && error.response.data && error.response.data.message){	 
				setSnackbarText(error.response.data.message); 
			}				
			if(error.response && error.response.status){
				if(error.response.status == 429){
					setSnackbarText('Много запросов, ждите 60 сек');
				}
			}
		});
	};

	// Click поделиться url 
	const ClickUrlFriends = e => {
		// Поделиться ссылкой
		bridge.send('VKWebAppShare', {
			link: 'https://vk.com/app51674843#' + urlShare
		}).then((data) => { 
			if (data.result) {	
				setFriends([]);
				setPredictedText('');
				setUrlShare(null);				
				setActiveModal(null);
				setStep('1')
				setSnackbar('success');  // error 				 	
				setSnackbarText('Ссылка успешно отправлена'); // error Text
			}
		}).catch((error) => {
			// Ошибка
			setSnackbar('error');  // error 				 	
			setSnackbarText('Ссылка не отправлена'); // error Text
		});
	}

	// Click удалить 
	const ClickDeletedFriends = e => {
		axios.post('https://predicted.botzik.ru/api/predicted/deleted?' + window.location.search.slice(1), {
		predictedId: idPredicted
		}).then(response => {
			// Проверяем статус error или success
			if (typeof response.data.status !== 'undefined') {
				// Проверка не прошла 
				if(response.data.status == 'error'){				   
					// Snackbar Error
					setSnackbar('error');  
					setSnackbarText(response.data.text);
				}
				// Проверка прошла  
				if(response.data.status == 'success'){	
					
					setPredictedAll(response.data.predicted_list);
					setFriends([]);
					setPredictedText('');
					setUrlShare(null);				
					setActiveModal(null);
 
					// Snackbar Error
					setSnackbar('success');  
					setSnackbarText('Предсказание удалено');

				}
			}
		}).catch(error => {
			// Snackbar error   
			setSnackbar('error');  	
			if(error.response && error.response.data && error.response.data.message){	 
				setSnackbarText(error.response.data.message); 
			}				
			if(error.response && error.response.status){
				if(error.response.status == 429){
					setSnackbarText('Много запросов, ждите 60 сек');
				}
			}
		});
	};
	 
	// Кнопка разрешить уведомления 
	const ClickNotifications = e => {
		setActiveModal(null); 
		bridge.send("VKWebAppAllowNotifications"); 
	};

	// Кнопка добавить в избранное
	const ClickFavorites = e => {
		setActiveModal(null); 
		// Окно с предложением добавить мини-приложение 
		bridge.send('VKWebAppAddToFavorites'); 
	};

	// Кнопка подписаться на сообщество
	const ClickJoinGroup = e => {
		setActiveModal(null); 
		// Подписатся на сообщество
		bridge.send('VKWebAppJoinGroup', {
			group_id: 179337349
		}) 
		.then((data) => { 
			if (data.result) {
				// Пользователь подписался на сообщество
			}
		})
		.catch((error) => {
			// Ошибка
			console.log(error);
		});
	};


	// Всплывающие окно 
	const modal = (
		<ModalRoot activeModal={activeModal}>
 
		    <ModalCard id="addFriends" onClose={() => setActiveModal(null)} 
		  		header="Создать предсказание"
			    subheader="Выберите друга и напишите предсказание, после чего отправьте ему ссылку."
			    icon={<Icon56Stars3Outline style={{color: '#fff'}} key="icon" />}
			>  
				{step == '1' && (
					<> 
					<FormItem>					 
						<Button onClick={ClickGetFriends} size="m" mode="primary" stretched>
							Выбрать друга
						</Button>
					</FormItem>
					</>
				)} 

				{step == '2' && ( friends.length > 0 && ( 
					<>  
					<FormItem>
						<SimpleCell 
							disabled
							after={ 
								<IconButton>
									<Icon28Cancel onClick={() => setStep('1')} />
								</IconButton>
							}
							before={<Avatar size={40} 
							src={friends[0].photo_200} 				
						/>} >
							{friends[0].first_name}
						</SimpleCell>
					</FormItem>	

					<FormItem htmlFor="predicted" top="Текст предсказание">
						<Textarea id="predicted" value={predictedText} onChange={handleTextareaChange}  />
					</FormItem>

					<FormItem>
						<Button onClick={ClickAdd} size="m" mode="primary" stretched>
							Сохранить
						</Button> 
					</FormItem>
					</>
				))} 

				{step == '3' && ( friends.length > 0 && ( 
					<>  
					<FormItem>
						<SimpleCell 
							disabled
							after={ 
								<IconButton>
									<Icon28Cancel onClick={() => setStep('1')} />
								</IconButton>
							}
							before={<Avatar size={40} 
							src={friends[0].photo_200} 				
						/>} >
							{friends[0].first_name}
						</SimpleCell>
					</FormItem>	

					<FormItem>
						<Button onClick={ClickUrlFriends} size="m" mode="primary" stretched>
							Отправить предсказание
						</Button> 
					</FormItem>
					</>
				))} 
			
		    </ModalCard>

			<ModalCard id="deletedFriends" onClose={() => setActiveModal(null)} 
		  		header="Предсказание"
			    subheader={<>Скопируйте и отправьте ссылку на предсказание. <br/> vk.com/app51674843#{idPredicted}</> }
			    icon={<Icon56Stars3Outline style={{color: '#fff'}} key="icon" />}
			>   

				<FormItem>
					<Button onClick={ClicCopyUrl} data-to={idPredicted} size="m" mode="primary" stretched>
						Скопировать ссылку  
					</Button>
				</FormItem>

				<FormItem>
					<Button onClick={ClickDeletedFriends} size="m" mode="primary" stretched>
						Удалить предсказание
					</Button>
				</FormItem>

		    </ModalCard>
 
			<ModalCard id="gift" onClose={() => setActiveModal(null)} 
		  		header="Бонусы"
			    subheader={<>Бесплатные магические шары <br/></> }
			    icon={<img src={mag} className='anim' alt="Предсказание"/>}
			>   

				<div className="boxBlock" style={{marginLeft: 0, marginRight: 0}}>
                    <SimpleCell
						style={{marginLeft: 0, marginRight: 0}}
                        className='boxOddboxinner'
                        disabled   
                        indicator="+10"                  
                        after={<img src={ratingImg} className='anim' alt="Предсказание"/> }  
                        subtitle={<>
						на стене и получай по 10
						<br/>
						магических шаров за каждого
						<br/>
						нового пользователя
						</>}
                     >
                       Поделись предсказанием
                    </SimpleCell>
                </div> 	
				<div className="boxBlock" style={{marginLeft: 0, marginRight: 0}}>
                    <SimpleCell
						style={{marginLeft: 0, marginRight: 0}}
                        className='boxOddboxinner'
                        disabled   
                        indicator="+5"                  
                        after={<img src={ratingImg} className='anim' alt="Предсказание"/> }  
                        subtitle={<> и отправь ссылку другу</>}
                     >  
                       Создай предсказание 
                    </SimpleCell>
                </div>
				<div className="boxBlock" style={{marginLeft: 0, marginRight: 0}}>
                    <SimpleCell
						style={{marginLeft: 0, marginRight: 0}}
                        className='boxOddboxinner'
                        disabled   
                        indicator="+1"                  
                        after={<img src={ratingImg} className='anim' alt="Предсказание"/> }  
                        subtitle={<>
						магические шары в 00:00 Мск
						</>}
                     > 
                       Получай каждый день  
                    </SimpleCell>
                </div>

		    </ModalCard>

			<ModalCard id="modalNotifications" onClose={() => setActiveModal(null)} 
		  		header="Уведомления"
			    subheader="Видим, что вы не в первый раз посещаете наше приложение. Хотите подписаться на уведомления, чтобы быть в курсе новых событий?"
			    actions={
					<ButtonGroup mode="vertical" align="center" gap="s" stretched> 

						<Button onClick={ClickNotifications} size="l" mode="primary" appearance="accent" stretched>
							Подписаться 
						</Button>
						<Button onClick={() => setActiveModal(null)} size="l" mode="primary" appearance="accent" stretched>
							Отказаться
						</Button>

					</ButtonGroup>
			   }
			   icon={<Icon56NotificationOutline key="icon" />}
			   >  
		   </ModalCard>

		    <ModalCard id="modalFavorites" onClose={() => setActiveModal(null)} 
		  		header="Добавить в избранное"
			    subheader="Не хотели бы вы добавить приложение в избранное? Это удобный способ быстрого доступа к нему в любое время, когда вам это понадобится"
			    actions={
					<ButtonGroup mode="vertical" align="center" gap="s" stretched> 

						<Button onClick={ClickFavorites} size="l" mode="primary" appearance="accent" stretched>
							Добавить 
						</Button>
						<Button onClick={() => setActiveModal(null)} size="l" mode="primary" appearance="accent" stretched>
							Отказаться
						</Button>

					</ButtonGroup>
			   }
			   icon={<Icon56FavoriteOutline key="icon" />}
			   >  
		    </ModalCard>

			<ModalCard id="modalJoinGroup" onClose={() => setActiveModal(null)} 
		  		header="Подписаться на сообщество"
			    subheader="Не хотели бы вы подписаться на наше сообщество, чтобы оставаться в курсе всех последних новостей?"
			    actions={
					<ButtonGroup mode="vertical" align="center" gap="s" stretched> 

						<Button onClick={ClickJoinGroup} size="l" mode="primary" appearance="accent" stretched>
							Подписаться 
						</Button>
						<Button onClick={() => setActiveModal(null)} size="l" mode="primary" appearance="accent" stretched>
							Отказаться
						</Button>

					</ButtonGroup>
			   }
			   icon={<Icon56NotificationOutline key="icon" />}
			   >  
		    </ModalCard>
 
		</ModalRoot>
	);

	return (
		<ConfigProvider appearance="dark">
			<AdaptivityProvider> 
				<AppRoot>
					<SplitLayout popout={popout} modal={modal}>
						<SplitCol>
							<View activePanel={activePanel}>

								<Start 
								id='start' 
								startUrls={startUrls}
								go={go} 
								setUserDb={setUserDb}
								/>

								<Home         
								id='home' 
								fetchedUser={fetchedUser} 
								go={go} 
								userDb={userDb}
								setUserDb={setUserDb}
								setActiveModal={setActiveModal}
								timer={timer} 
								setSnackbar={setSnackbar}
								setSnackbarText={setSnackbarText}  
								/>

								<Friends       
								id='friends' 
								fetchedUser={fetchedUser} 
								go={go} 
								userDb={userDb}
								setUserDb={setUserDb}
								hash={hash}
								setActivePanel={setActivePanel}
								timer={timer} 
								setSnackbar={setSnackbar}
								setSnackbarText={setSnackbarText}  
								/>
 
								<MyPredictions  
								id='myPredictions' 
								go={go} 
								setUserDb={setUserDb}
								predictedAll={predictedAll}
								setPredictedAll={setPredictedAll}
								setIdPredicted={setIdPredicted}
								setActiveModal={setActiveModal}
								setSnackbar={setSnackbar}
								setSnackbarText={setSnackbarText}  
								setPopout={setPopout}
								/>

								<Rating 
								id='rating' 
								go={go} 
								setUserDb={setUserDb}
								setSnackbar={setSnackbar}
								setSnackbarText={setSnackbarText}  
								setPopout={setPopout}
								/>
 
							</View>
							{snackbarBlock} 
						</SplitCol>
					</SplitLayout>
				</AppRoot>
			</AdaptivityProvider>
		</ConfigProvider>
	);
}

export default App;
