import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { ScreenSpinner, Panel, PanelHeader, Button, SimpleCell, IconButton, FormStatus, PanelHeaderBack, Group } from '@vkontakte/vkui';
import { Icon16CheckDoubleOutline, Icon28Menu, Icon16CheckOutline } from '@vkontakte/icons'; 
 

const MyPredictions = ({ id, go, setUserDb, predictedAll, setPredictedAll, setIdPredicted, setActiveModal, setPopout, setSnackbar, setSnackbarText }) => { 

 
	
 
// @ useEffect
useEffect(() => {
 
	// @ Отправка axios 
	axios.get('https://predicted.botzik.ru/api/predicted/view/all?'+window.location.search.slice(1))
	.then(response => {		 
		// Проверка прошла 
		if(response.data.status == 'success'){	

			// Вывод  
			setPredictedAll(response.data.predicted_list); 	
			setUserDb(response.data.user);		 
			// Выключить Spinner 
			setPopout(null); 	
			   
		}else{   
			// Snackbar error			 
			setSnackbar('error');  
			// Snackbar Text 				 	
			setSnackbarText(response.data.text);  
			// Выключить Spinner 
		setPopout(null);
		} 
	}).catch(error => {	
		// Snackbar error		  
		setSnackbar('error'); 
		// Snackbar Text			 
		setSnackbarText(error.message);  
		// Выключить Spinner 
		setPopout(null);
	});

}, []);

// Удалить 
const ClickDeleted = e => {
	setIdPredicted(e.currentTarget.dataset.to);
	setActiveModal('deletedFriends');
}; 
 
 
return ( 
	<Panel id={id}>
		<PanelHeader
			before={<PanelHeaderBack onClick={go} data-to="home"/>}
		>
			Мои предсказания
		</PanelHeader>
 
		<Group className='Bg'>

			<div className="boxBlock" style={{marginTop: 25}}>
				<div className="boxOddboxinner" style={{padding: 20, fontFamily: 'boorsok', lineHeight: '30px'}}>
					<div style={{fontSize: 24, paddingBottom: 10}}>Порадуйте друзей или вторую половину своим личным предсказанием</div>
					Напишите что-то забавное и интересное, что сможет заставить их почувствовать себя особенными и улучшить настроение на весь день. 
				</div>
			</div>
 
			<div style={{textAlign: 'center', margin: '40px 40px 30px 40px'}}>  
				<Button onClick={() => setActiveModal('addFriends')} className='predicted-btn-rose' size="l">Создать</Button>
			</div>

			{predictedAll.length > 0 && (
			<>  
		 
			{predictedAll.map((itemHome, index) => (
				<div key={index} className="boxBlock" >
					 <SimpleCell				 	
						className='boxOddboxinner'
						disabled
						badgeAfterTitle={itemHome.status == '1' ? (<Icon16CheckDoubleOutline style={{color: '#fff'}} />):(<Icon16CheckOutline style={{color: '#cdcdcd'}} />)}
						after={<Icon28Menu  onClick={ClickDeleted} data-to={itemHome.id} style={{color: 'rgb(213 213 213)', cursor: 'pointer'}} />}  
						subtitle={itemHome.text}
					>
						{itemHome.user_name_to}   
					</SimpleCell> 
				</div> 	
			) 
			)} 
			</>
			)}	
		</Group>
	</Panel>
)};

MyPredictions.propTypes = {
	id: PropTypes.string.isRequired,
	go: PropTypes.func.isRequired,
};

export default MyPredictions;
