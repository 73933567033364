import React, { useState, useEffect } from 'react';
import bridge from '@vkontakte/vk-bridge';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Panel, PanelHeader, PanelHeaderButton, Header, ButtonGroup, Button, Group, Cell, Div, Avatar, Placeholder } from '@vkontakte/vkui';
import { Icon28Notifications } from '@vkontakte/icons'; 

import MagicBall from './../components/MagicBall';
 
const Friends = ({ id, go, fetchedUser, userDb, setUserDb, hash, setActivePanel, timer, setSnackbar, setSnackbarText }) => { 

	const [predictedFriends, setPredictedFriends] = useState(null);
	const [spinner, setSpinner] = useState(true);
 
	// @ useEffect
	useEffect(() => {
		
		setSpinner(false);

		// @ Отправка axios 
		axios.get('https://predicted.botzik.ru/api/predicted/hash/'+hash+'?'+window.location.search.slice(1))
		.then(response => {		 
			// Проверка прошла 
			if(response.data.status == 'success'){	
				  
				if(response.data.predicted_list){
					// Вывод 
					setPredictedFriends(response.data.predicted_list);
				}else{
					// Личное предсказания не найдено
					setActivePanel('home');
				}
	
			}else{     
				// Snackbar error			 
				setSnackbar('error');  
				// Snackbar Text 				 	
				setSnackbarText(response.data.text);  
			} 
		}).catch(error => {	 
			// Snackbar error		  
			setSnackbar('error'); 
			// Snackbar Text			 
			setSnackbarText(error.message);  
		});	 

	}, []);

	 
	// Клик на кнопку узнать
	const clickView = e => {

		// @ Отправка axios 
		axios.get('https://predicted.botzik.ru/api/predicted/hash/'+hash+'/status?'+window.location.search.slice(1))
		.then(response => {		
			console.log(response.data);
			// Проверка прошла 
			if(response.data.status == 'success'){	

				if(response.data.predicted_list){
					// Вывод 
					setPredictedFriends(response.data.predicted_list);
					setSpinner(true);
				}else{
					// Личное предсказания не найдено
					setActivePanel('home');
				}
	
			}else{     
				// Snackbar error			 
				setSnackbar('error');  
				// Snackbar Text 				 	
				setSnackbarText(response.data.text);  
			} 
		}).catch(error => {	 
			// Snackbar error		  
			setSnackbar('error'); 
			// Snackbar Text			 
			setSnackbarText(error.message);  
		});	 	 
	}


	// Клик на кнопку поделиться
	const clickShare = e => {

		bridge.send('VKWebAppShowWallPostBox', {
			message: 'Предсказание на день! \n' + predictedFriends.text,
			attachment: 'https://vk.com/app51674843#ref' + userDb.user_id,
			owner_id: fetchedUser.id
		  }).then( (data) => {
				// Snackbar error		  
				setSnackbar('success'); 
				// Snackbar Text			 
				setSnackbarText('Ссылка успешно отправлена');  
		  }).catch( (e) => {
				// Snackbar error		  
				setSnackbar('error'); 
				// Snackbar Text			 
				setSnackbarText('Запись не была добавлена'); 	
		  })

	}
 
	 
	return ( 
	<Panel id={id}>
		 
		<PanelHeader>Предсказание</PanelHeader>	
 					  								 
		<Placeholder 
		header={<>{fetchedUser && (<>{fetchedUser.first_name},</>)} для Вас есть предсказание.<br/>  Хотите заглянуть в будущее? </>}>
		<>  

			{predictedFriends && (  
			predictedFriends.status == '1' ? (
				<>
				<div className="box box1">
					<div className="oddboxinner"> {predictedFriends.text}	</div>
				</div>
				<Button onClick={clickShare} className='predicted-btn-violet' size="l">Поделиться </Button>
				<Button onClick={go} data-to="home" className='predicted-btn-rose' size="l">Еще</Button>		
				<div style={{marginTop: 30}}>
				Порадуйте ваших друзей <br/>своим личным предсказанием
				</div>		
				<br/>
				<Button onClick={go} data-to="myPredictions" className='predicted-btn-red' size="l">Подробней</Button>			 
				</>  
				):(
				<>
				<MagicBall/>
				<Button onClick={clickView} className='predicted-btn-rose' size="l">Узнать</Button>
				</>
			))
			}		
		</>	

        </Placeholder>
 
	</Panel>
)};

Friends.propTypes = {
	id: PropTypes.string.isRequired,
	go: PropTypes.func.isRequired,
	fetchedUser: PropTypes.shape({
		photo_200: PropTypes.string,
		first_name: PropTypes.string,
		last_name: PropTypes.string,
		city: PropTypes.shape({
			title: PropTypes.string,
		}),
	}), 
};
 
export default Friends;
