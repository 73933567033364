import React, { useState, useEffect } from 'react';

import image1 from "./../img/mag1.png";
import image2 from "./../img/mag2.png";
import image3 from "./../img/mag3.png";
import image4 from "./../img/mag4.png";
const images = [image1, image2, image3, image4];

const MagicBall = () => {
 
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((index + 1) % images.length);
    }, 1000);
    return () => clearInterval(interval);
  }, [index]);

  return (
      <div className="animation-container">
				{images.map((image, i) => (
					<img
					key={i}
					className={i === index ? "active" : ""}
					src={image}
					alt={`image${i + 1}`}
					/>
				))}
			</div>
  );
}

export default MagicBall;